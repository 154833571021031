<template>
    <div class="main_container">
        <el-row :gutter="12">
            <el-col :span="4">
                <div class="main_page">
                    <div class="page_title">标签分组
                        <el-button class="btn" type="text" icon="el-icon-plus" size="small" @click="doGroupForm(null)">
                            新增
                        </el-button>
                    </div>
                    <div class="page_containt" style="padding:0">
                        <div class="page_box" :style="{ height: (tableHeight + 120) + 'px' }">
                            <div class="lable_box" v-loading="groupLoading">
                                <div class="row" :class="{ cur: searchForm.group_id == null }" @click="seleteGroup(null)">
                                    <div class="name">全部标签</div>
                                </div>
                                <div class="row" @click="seleteGroup(item.id)" v-for="item in groupList" :key="item.id"
                                    :class="{ cur: searchForm.group_id == item.id }">
                                    <div class="name">{{ item.name }}</div>
                                    <div class="tool">
                                        <el-tooltip effect="dark" content="编辑" placement="top">
                                            <el-button class="btn" type="text" icon="el-icon-edit" size="small"
                                                @click.stop="doGroupForm(item)">
                                            </el-button>
                                        </el-tooltip>
                                        <el-tooltip effect="dark" content="删除" placement="top">
                                            <el-button class="btn" type="text" icon="el-icon-delete" size="small"
                                                @click.stop="deleteGroupItem(item)">
                                            </el-button>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="20">
                <div class="main_page">
                    <div class="page_title">标签列表</div>
                    <div class="page_containt">
                        <div class="page_search">
                            <el-form ref="searchForm" :model="searchForm" size="small">
                                <el-row type="flex">
                                    <el-col class="search_col">
                                        <el-form-item label="" size="small" prop="name">
                                            <el-input size="small" type="text" v-model="searchForm.name"
                                                style="width: 180px" placeholder="标签名称" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col class="search_col">
                                        <el-button type="primary" size="small" native-type="submit"
                                            @click.native.prevent="handleSearch">搜索</el-button>
                                    </el-col>
                                    <el-col class="search_col">
                                        <el-button size="small" @click="resetSearchForm">重置</el-button>
                                    </el-col>
                                    <el-col class="btn_col" v-if="searchForm.group_id!=null">
                                        <el-button type="primary" size="small" @click="doForm(null)">新增标签</el-button>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </div>
                        <div class="page_box" ref="pageBox">
                            <el-table v-loading="loading" size="small" class="el_table" cell-class-name="el_table_cell"
                                header-row-class-name="el_table_header" :data="listData" :height="tableHeight"
                                style="width: 100%">
                                <el-table-column prop="name" label="标签名称"></el-table-column>
                                <el-table-column prop="order_num" label="排序号" width="60"></el-table-column>
                                <el-table-column fixed="right" align="right" header-align="right" label="操作" width="130">
                                    <template slot-scope="scope">
                                        <el-button class="btn" type="text" icon="el-icon-edit" size="small"
                                            @click="doForm(scope.row)">
                                            编辑
                                        </el-button>
                                        <el-button @click="deleteItem(scope.row)" class="btn" type="text"
                                            icon="el-icon-delete" size="small">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-dialog :title="groupTitle" :close-on-press-escape="false" :close-on-click-modal="false"
            :visible.sync="groupVisible" @close="groupVisible = false" width="400px">
            <el-form ref="groupModelForm" :model="groupModelForm" :rules="groupModelRules" label-width="100px" size="small"
                class="form_box">
                <el-form-item label="分组名称：" class="el-form-item-no" prop="name">
                    <el-input size="small" v-model="groupModelForm.name" placeholder="分组名称"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" native-type="submit" style="width: 80px"
                        @click.native.prevent="handleGroupSubmit" size="small" :loading="submitLogining">确定</el-button>
                    <el-button style="width: 80px" @click="groupVisible = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="formTitle" :close-on-press-escape="false" :close-on-click-modal="false" :visible.sync="visible"
            @close="visible = false" width="400px">
            <el-form ref="modelForm" :model="modelForm" :rules="modelRules" label-width="100px" size="small"
                class="form_box">
                <el-form-item label="标签名称：" class="el-form-item-no" prop="name">
                    <el-input size="small" v-model="modelForm.name" placeholder="标签名称"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" native-type="submit" style="width: 80px" @click.native.prevent="handleSubmit"
                        size="small" :loading="submitLogining">确定</el-button>
                    <el-button style="width: 80px" @click="visible = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import vipApi from "@/api/vip";
export default {
    components: {
    },
    data() {
        return {
            loading: false,
            groupLoading: false,
            submitLogining: false,
            visible: false,
            groupVisible: false,
            groupTitle: '',
            formTitle: '',
            groupList: [],
            listData: [],
            tableHeight: 500,
            searchForm: {
                group_id: null,
                name: ''
            },
            model: null,
            groupModelForm: {
                name: ''
            },
            groupModelRules: {
                name: [{ required: true, message: "请输入" }]
            },
            modelForm: {
                name: ''
            },
            modelRules: {
                cName: [{ required: true, message: "请输入" }]
            },
        };
    },
    mounted() {
        onTableHeight(this,false);
        this.getGroupList();
        this.handleSearch();
    },
    methods: {
        //查询
        handleSearch() {
            this.getList();
        },
        resetSearchForm() {
            if (this.$refs.searchForm) this.$refs.searchForm.resetFields();
            this.handleSearch();
        },
        //分组列表
        getGroupList() {
            this.groupLoading = true;
            vipApi.labelGroupList().then((res) => {
                this.groupList = res.list
            }).catch((res) => {

            }).finally(() => {
                this.groupLoading = false;
            })
        },
        seleteGroup(id) {
            this.searchForm.group_id = id;
            this.getList();
        },
        //标签列表
        getList() {
            this.loading = true;
            vipApi
                .labelList({
                    ...this.searchForm,
                })
                .then((res) => {
                    this.listData = res.list;
                })
                .catch((res) => {

                }).finally(() => {
                    this.loading = false;
                })
        },
        //表单
        doGroupForm(row) {
            this.model = row;
            this.groupTitle = this.model == null ? "新增分组" : "编辑分组";
            this.groupModelForm = {
                name: ''
            };
            if (this.model != null) {
                this.groupModelForm.name = this.model.name;
            }
            this.groupVisible = true;
            if (this.$refs.groupModelForm) this.$refs.groupModelForm.resetFields();
        },
        //删除
        deleteGroupItem(row) {
            this.$confirm("确定是否删除该分组?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let message = this.$message({
                        message: "请求中",
                        iconClass: "el-icon-loading",
                        customClass: "message-loading",
                        duration: 0,
                        offset: 60,
                    });
                    vipApi
                        .labelGroupDelete({
                            id: row.id
                        })
                        .then((res) => {
                            message.close();
                            this.$message({
                                message: "删除成功",
                                type: "success",
                                offset: 60,
                            });
                            this.getGroupList();
                            if (this.searchForm.group_id == row.id) {
                                this.searchForm.group_id = null;
                                this.getList();
                            }
                        })
                        .catch((res) => {
                            message.close();
                        });
                })
                .catch(() => { });
        },
        //提交
        handleGroupSubmit() {
            this.$refs.groupModelForm.validate((valid) => {
                if (valid) {
                    this.submitLogining = true;
                    vipApi
                        .labelGroupForm({
                            id: this.model == null ? 0 : this.model.id,
                            ...this.groupModelForm,
                        })
                        .then((res) => {
                            this.getGroupList();
                            this.groupVisible = false;
                        })
                        .catch((res) => {


                        }).finally(() => {
                            this.submitLogining = false;
                        })
                }
            });
        },
        //表单
        doForm(row) {
            this.model = row;
            this.formTitle = this.model == null ? "新增标签" : "编辑标签";
            this.modelForm = {
                name: ''
            };
            if (this.model != null) {
                this.modelForm.name = this.model.name;
            }
            this.visible = true;
            if (this.$refs.modelForm) this.$refs.modelForm.resetFields();
        },
        //删除
        deleteItem(row) {
            this.$confirm("确定是否删除该标签?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let message = this.$message({
                        message: "请求中",
                        iconClass: "el-icon-loading",
                        customClass: "message-loading",
                        duration: 0,
                        offset: 60,
                    });
                    vipApi
                        .labelDelete({ id: row.id })
                        .then((res) => {
                            message.close();
                            this.$message({
                                message: "删除成功",
                                type: "success",
                                offset: 60,
                            });
                            this.getList();
                        })
                        .catch((res) => {
                            message.close();
                        });
                })
                .catch(() => { });
        },
        //提交
        handleSubmit() {
            this.$refs.modelForm.validate((valid) => {
                if (valid) {
                    this.submitLogining = true;
                    vipApi
                        .labelForm({
                            id: this.model == null ? 0 : this.model.id,
                            group_id:this.searchForm.group_id,
                            ...this.modelForm,
                        })
                        .then((res) => {
                            this.$message({
                                message: "提交成功",
                                type: "success",
                                offset: 60,
                            });
                            this.getList();
                            this.visible = false;
                        })
                        .catch((res) => {


                        }).finally(() => {
                            this.submitLogining = false;
                        })
                }
            });
        },
    },
};
</script>
<style scoped>
.page_title .btn {
    float: right;
    margin-right: 12px;
}

.lable_box {
    height: 100%;
    overflow-y: auto;
}

.lable_box .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    padding: 8px 12px;
    border-bottom: 1px solid #ebeef5;
    color: #333;
    min-height: 32px;
    cursor: pointer;
}

.lable_box .row.cur,
.lable_box .row:hover {
    background: #f9f9f9;
    color: #00ce9b;
    /* color:#000 */
}

.lable_box .row:hover .tool .btn {
    display: inline;
}

.lable_box .row .name {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.lable_box .row .tool {
    padding-left: 10px;
    width: 40px;

}

.lable_box .row .tool .btn {
    color: #333;
    display: none;
}
</style>>